import { MainNavigationMenu } from 'ui';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

import { ROUTES } from 'routes/routes.const';
import { useAppSelector } from 'hooks';
import { getMainOrganization } from 'reduxStore/organization/organizationSelectors';

const NavigationLogically = () => {
  const mainOrgName = useAppSelector(getMainOrganization)?.name;
  const navigationOptions = [
    {
      title: 'Overview',
      url: ROUTES.DASHBOARD,
      icon: <DashboardCustomizeOutlinedIcon />,
    },
    {
      // TODO: Fix Setup as a menu item - it is just drop down
      title: 'Setup',
      url: ROUTES.SET_UP,
      icon: <SettingsOutlinedIcon />,
    },
    {
      title: 'Services',
      url: ROUTES.SERVICES,
      icon: <HomeRepairServiceOutlinedIcon />,
    },
    {
      title: 'Clients',
      url: ROUTES.CLIENTS,
      icon: <PersonOutlineOutlinedIcon key="users" />,
    },
    {
      title: 'Team',
      url: ROUTES.TEAM_MEMBERS,
      icon: <GroupOutlinedIcon />,
    },
    {
      title: 'Ticketing',
      url: ROUTES.TICKETS,
      icon: <LocalActivityOutlinedIcon />,
    },
    {
      title: 'Audit Logs',
      url: ROUTES.AUDIT_LOGS,
      icon: <TerminalOutlinedIcon />,
    },
  ];

  return (
    <MainNavigationMenu options={navigationOptions} orgName={mainOrgName} />
  );
};

export default NavigationLogically;
