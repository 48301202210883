import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getHuntressGeneralStatistics,
  getSelectedOrgId,
} from 'reduxStore/toolHuntress/toolHuntressSelectors';
import {
  IncidentsCountersWrapper,
  Title,
  Wrapper,
  MenusWrapper,
} from './HuntressDashboardPage.styled';
import { FlatButton, StatisticsCard, StatusBox } from 'ui';
import { Outlet, useMatch, useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useEffect } from 'react';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/toolHuntress/toolHuntressOperations';
import { IStatisticsCardData } from 'types/table.type';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const HuntressDashboardPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const generalStatistics = useAppSelector(getHuntressGeneralStatistics);
  const selectedOrgId = useAppSelector(getSelectedOrgId);
  const sumOfActiveIncidents =
    (generalStatistics?.incidents.sent.crit_count ?? 0) +
    (generalStatistics?.incidents.sent.high_count ?? 0) +
    (generalStatistics?.incidents.sent.low_count ?? 0);

  const childSelected = useMatch('/huntress_dashboard') === null;

  const reformatedDefenders: IStatisticsCardData[] = [
    {
      icon: <ShieldOutlinedIcon fontSize="small" />,
      label: 'Protected',
      counter:
        (generalStatistics?.defenders.protected_count ?? 0) +
        (generalStatistics?.defenders.healthy_count ?? 0),
    },
    {
      icon: <FmdBadOutlinedIcon fontSize="small" />,
      label: 'Unhealthy',
      counter: generalStatistics?.defenders.unhealthy_count,
    },
    {
      icon: <LockOpenOutlinedIcon fontSize="small" />,
      label: 'Unmanaged',
      counter: generalStatistics?.defenders.unmanaged_count,
    },
    {
      icon: <IndeterminateCheckBoxOutlinedIcon fontSize="small" />,
      label: 'Incompatible',
      counter: generalStatistics?.defenders.incompatible_count,
    },
  ];

  const reformatedAgents: IStatisticsCardData[] = [
    {
      icon: <ShieldOutlinedIcon fontSize="small" />,
      label: 'Protected',
      counter: generalStatistics?.agents.protected_count,
    },
    {
      icon: <FmdBadOutlinedIcon fontSize="small" />,
      label: 'Unresponsive',
      counter: generalStatistics?.agents.unresponsive_count,
    },
    {
      icon: <AccessTimeOutlinedIcon fontSize="small" />,
      label: 'Outdated',
      counter: generalStatistics?.agents.outdated_count,
    },
    {
      icon: <LockOutlinedIcon fontSize="small" />,
      label: 'Isolated',
      counter: generalStatistics?.agents.isolated_count,
    },
  ];

  const agentsSum =
    (generalStatistics?.agents.isolated_count ?? 0) +
    (generalStatistics?.agents.outdated_count ?? 0) +
    (generalStatistics?.agents.protected_count ?? 0) +
    (generalStatistics?.agents.unresponsive_count ?? 0);

  useEffect(() => {
    dispatch(retrieveHuntressGeneralStatistics(selectedOrgId));
  }, [dispatch, selectedOrgId]);

  const handleChildSelection = (route: any) => {
    navigate(route);
  };

  return (
    <Wrapper>
      <MenusWrapper>
        <FlatButton
          onClick={() => handleChildSelection(ROUTES.HUNTRESS_DASHBOARD_AGENTS)}
          variant="contained"
          width="134px"
          height="50px"
        >
          Agents
        </FlatButton>
        <FlatButton
          onClick={() =>
            handleChildSelection(ROUTES.HUNTRESS_DASHBOARD_INCIDENTS)
          }
          variant="contained"
          width="134px"
          height="50px"
        >
          Incidents
        </FlatButton>
        <FlatButton
          onClick={() =>
            handleChildSelection(ROUTES.HUNTRESS_DASHBOARD_REPORTS)
          }
          variant="contained"
          width="134px"
          height="50px"
        >
          Reports
        </FlatButton>
      </MenusWrapper>
      {!childSelected ? (
        <Wrapper>
          <Title>Active Incidents ({sumOfActiveIncidents})</Title>
          <IncidentsCountersWrapper>
            <StatusBox status="critical" isstarticon="true" width="150px">
              {generalStatistics?.incidents.sent.crit_count}
            </StatusBox>
            <StatusBox status="high" isstarticon="true" width="150px">
              {generalStatistics?.incidents.sent.high_count}
            </StatusBox>
            <StatusBox status="low" isstarticon="true" width="150px">
              {generalStatistics?.incidents.sent.low_count}
            </StatusBox>
          </IncidentsCountersWrapper>
          <StatisticsCard
            title="Managed Antivirus"
            subTitle="Microsoft Defender"
            statistics={reformatedDefenders}
          />
          <StatisticsCard
            title={`Agents (${agentsSum})`}
            subTitle="Status"
            statistics={reformatedAgents}
          />
        </Wrapper>
      ) : (
        <Outlet />
      )}
    </Wrapper>
  );
};

export default HuntressDashboardPage;
