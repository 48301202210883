import { Typography, styled } from '@mui/material';
import Container, { ContainerProps } from '@mui/material/Container';
import Box, { BoxProps } from '@mui/material/Box';
import { POPPINS } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface IBoxProps extends BoxProps {
  background?: string;
}

export const StyledContainer = styled(Container)<ContainerProps>(() => ({
  width: '100%',
}));

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '79px 9vw 21px',
  width: '100vw',
  minHeight: '100vh',
  height: 'fit-content',
  boxSizing: 'border-box',
}));

export const StyledWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '18px',
}));

export const StyledWrapperWhite = styled(Box)<IBoxProps>(() => ({
  background: COLORS.white,
  padding: '49px 76px 32px 76px',
  borderRadius: '18px',
  width: '100%',
  position: 'relative',
  height: 'fit-content',
  minHeight: '70vh',
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: POPPINS,
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '48px',
  letterSpacing: '-0.704px',
  textTransform: 'capitalize',
  marginBottom: '7px',
}));
