export const COLORS = {
  main: '#037BC0',
  secondary: '#232C34',
  light: '#42a5f5',
  pantone: '#0060AE',
  skyBlue: '#12A0DD',
  snowBlue: '#227CC7',
  purple: '#6200EE',
  paradise: '#AEE1FF',
  oceanBlue: '#AEE0FE',
  lightBlue: '#F0F9FF',
  superLightBlue: '#D1EEFF',
  lightSkyBlue: '#A8DCFF',
  blue: '#B7DCF0',
  blue100: '#F1FAFF',
  superBlue: '#2075BC',
  superLilac: '#AAC2FF',
  divider: '#C4D1FF',
  green50: '#247C80',
  greenMain: '#3B9B39',
  greenDark: '#14A645',
  greenLight: '#AFF4C6',
  superLightGreen: '#00FB54',
  teal: '#26A69A',
  orange: '#EE6002',
  orangeMain: '#EBBE2A',
  orangeRed: '#F3734A',
  yellowLight: '#D5CE1E',
  superYellow: '#FFCD29',
  darkOrange: '#C29500',
  yellowDark: '#FFC107',
  yellowSilver: '#FFF8E0',
  silver: '#FFFCF3',
  superSilver: '#E9E9E9',
  silverLight: '#DCDCDC',
  white: '#fff',
  disableBackground: '#EEEEEE',
  grayBackgroundPrimary: '#F9F9F9',
  gray: '#A9A9A9',
  grayBackgroundDark: '#B8B8B8',
  grayBorder: '#B3B3B3',
  grayDarkBorder: '#9D9D9D',
  grayText: '#777',
  darkGray: '#9E9E9E',
  grayBlue: '#AECBED',
  grafit: '#626262',
  brown: '#4D3C10',
  secondaryGray: '#595A5C',
  contrastGray: '#D9D9D9',
  disabled: '#989898',
  hover: '#DADADA',
  black: '#404040',
  minBlack: '#373737',
  secondaryBlack: '#232323',
  darkBlack: '#000',
  redBackground: '#FF90AA',
  redMain: '#B5153B',
  redLight: '#ED4065',
  redDark: '#C90D00',
  tileRed: '#982323',
  error: '#D50032',
  authError: '#FF0303',
};
