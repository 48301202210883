import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/createProfile';
import {
  ICreateMasterBody,
  IImportUsersBody,
  IUpdateOrganizationBody,
  ISetUpSelectedCloudsBody,
  ICreateContactBody,
  ICreateClientBody,
  IReceiveToolOrgsBody,
  ICreateIntegrationBody,
  IDeleteIntgrnBody,
  IDeleteContactBody,
} from 'types/api.type';

//TODO: Revise all functions and delete unused
const createMaster = createAsyncThunk(
  'createProfile/createMaster',
  async (body: ICreateMasterBody, { rejectWithValue }) => {
    try {
      const id = await api.postCreateMaster(body);
      return id;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'Creating master organization error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const createClient = createAsyncThunk(
  'createProfile/createClient',
  async (body: ICreateClientBody, { rejectWithValue }) => {
    try {
      const id = await api.postCreateClient(body);
      return id;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'Creating client error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const updateOrganization = createAsyncThunk(
  'createProfile/updateOrganization',
  async (body: IUpdateOrganizationBody, { rejectWithValue }) => {
    try {
      await api.postUpdateOrganization(body);
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'Updating organization error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const importUsersList = createAsyncThunk(
  'createProfile/importUsers',
  async (body: IImportUsersBody, { rejectWithValue }) => {
    try {
      const result = await api.postImportUsers(body);
      return result;
    } catch (error: any) {
      console.error('Error:', error.message);
      return rejectWithValue(error.message);
    }
  }
);

const retrieveClouds = createAsyncThunk(
  'createProfile/retrieveClouds',
  async (orgId: number | null, { rejectWithValue }) => {
    try {
      const result = await api.postGetClouds(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving clouds error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
const setUpClouds = createAsyncThunk(
  'createProfile/setUpClouds',
  async (body: ISetUpSelectedCloudsBody, { rejectWithValue }) => {
    try {
      const result = await api.postSetUpClouds(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'setting up clouds error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
const retrieveRoles = createAsyncThunk(
  'createProfile/retrieveRoles',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveRoles(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving roles error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const createContact = createAsyncThunk(
  'createProfile/createContact',
  async (body: ICreateContactBody, { rejectWithValue }) => {
    try {
      const result = await api.postCreateContact(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'creating contact error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveContacts = createAsyncThunk(
  'createProfile/retrieveContacts',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveContacts(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving contacts error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveAssignableContacts = createAsyncThunk(
  'createProfile/retrieveAssignableContacts',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveAssignableContacts(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving assignable contacts error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveClients = createAsyncThunk(
  'createProfile/retrieveClients',
  async (tool_id: number | null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveClients(tool_id);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving clients error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveClientsRef = createAsyncThunk(
  'createProfile/retrieveClientsRef',
  async (tool_id: number | null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveClientsRef(tool_id);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving clients as references error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveTools = createAsyncThunk(
  'createProfile/retrieveTools',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveTools(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving tools error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveToolsRef = createAsyncThunk(
  'createProfile/retrieveToolsRef',
  async (def: null, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveToolsRef(def);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving tools as references error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const checkAPICredentials = createAsyncThunk(
  'createProfile/checkAPICredentials',
  async (body: IReceiveToolOrgsBody, { rejectWithValue }) => {
    try {
      const result = await api.postCheckAPICredentials(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'checking API credentials error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const createIntegration = createAsyncThunk(
  'createProfile/createIntegration',
  async (body: ICreateIntegrationBody, { rejectWithValue }) => {
    try {
      const result = await api.postCreateIntegration(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'creating integration error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveToolOrgs = createAsyncThunk(
  'createProfile/retrieveToolOrgs',
  async (toolId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveToolOrgs(toolId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving tool organizations error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const deleteIntegration = createAsyncThunk(
  'createProfile/deleteIntegration',
  async (body: IDeleteIntgrnBody, { rejectWithValue }) => {
    try {
      const result = await api.postDeleteIntegration(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'deleting integration error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const deleteClient = createAsyncThunk(
  'createProfile/deleteClient',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postDeleteClient(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'deleting client error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const deleteContact = createAsyncThunk(
  'createProfile/deleteContact',
  async (body: IDeleteContactBody, { rejectWithValue }) => {
    try {
      const result = await api.postDeleteContact(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'deleting contact error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export {
  createMaster,
  createClient,
  updateOrganization,
  importUsersList,
  retrieveClouds,
  setUpClouds,
  retrieveRoles,
  createContact,
  retrieveContacts,
  retrieveAssignableContacts,
  retrieveClients,
  retrieveClientsRef,
  retrieveTools,
  retrieveToolsRef,
  checkAPICredentials,
  createIntegration,
  retrieveToolOrgs,
  deleteIntegration,
  deleteClient,
  deleteContact,
};
