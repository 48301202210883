import {
  IHuntressIncidentsBody,
  IHuntressReportsBody,
} from 'types/huntress.type';
import instance from './api';

export const postRetrieveHuntressGeneralStatistics = async (orgId: number) => {
  const { data } = await instance.post('v1/huntress-stats', { org_id: orgId });

  return data;
};

export const postRetrieveHuntressAgents = async (orgId: number) => {
  const { data } = await instance.post('v1/huntress-agents', { org_id: orgId });

  return data;
};

export const postRetrieveHuntressIncidentsRefs = async (orgId: number) => {
  const { data } = await instance.post('v1/huntress-incident-refs', {
    org_id: orgId,
  });

  return data;
};

export const postRetrieveHuntressIncidents = async (
  body: IHuntressIncidentsBody
) => {
  const { data } = await instance.post('v1/huntress-incidents', body);

  return data;
};

export const postRetrieveHuntressReportsRefs = async (orgId: number) => {
  const { data } = await instance.post('v1/huntress-report-refs', {
    org_id: orgId,
  });

  return data;
};

export const postRetrieveHuntressReports = async (
  body: IHuntressReportsBody
) => {
  const { data } = await instance.post('v1/huntress-reports', body);

  return data;
};
