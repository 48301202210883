import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainerMSP } from 'views';

const TeamMembersConfig = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(ROUTES.CLIENTS_CONFIG);
  };

  const handleBack = () => {
    navigate(ROUTES.ORGANIZATION_PROFILE);
  };

  return (
    <ButtonsContainerMSP
      titleButton="Next"
      type="submit"
      onClickBack={handleBack}
      onClick={handleSubmit}
    />
  );
};

export default TeamMembersConfig;
