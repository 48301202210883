import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { Formik, Form, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import {
  Title,
  SubTitle,
  WrapperAddClient,
  MenuItemBox,
  MenuItemText,
  Error,
} from './AddClient.styled';
import { FlatButton } from 'ui';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import {
  IAdminsForClientV2Body,
  IAssignableContactBody,
  ICreateClientBody,
} from 'types/api.type';
import {
  createClient,
  retrieveAssignableContacts,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getAssignableContacts,
  getCreateProfileError,
} from 'reduxStore/createProfile/createProfileSelectors';
import countryList from 'react-select-country-list';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

interface IClientFormValues {
  name: string;
  street: string;
  street2: string | undefined;
  city: string;
  state: string;
  country_code: string;
  admins: string[];
  clientAdminFirstName: string;
  clientAdminLastName: string;
  clientAdminEmail: string;
  clientAdminPhone: string | null;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Client name is required'),
  clientAdminEmail: Yup.string()
    .email('Invalid email')
    .required('Client Admin Email is required'),
  street: Yup.string().required('Street is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State/Region is required'),
  country_code: Yup.string().required('Country is required'),
});

const ReformatContact = (arr: (string | undefined)[]) => {
  const updatedContact = arr.map(value => (value === null ? 'Missing' : value));

  return updatedContact
    .map((value, index) =>
      index >= 0 && index < 2 ? ` ${value}` : ` - ${value}`
    )
    .join(' ');
};

const AddClient = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationId = useAppSelector(getMainOrganizationID);
  const contacts = useAppSelector(getAssignableContacts);
  const error = useAppSelector(getCreateProfileError);

  const countryOptions = countryList().getData();

  useEffect(() => {
    dispatch(retrieveAssignableContacts(organizationId));
  }, [dispatch, organizationId]);

  const initialValues: IClientFormValues = {
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    country_code: 'US',
    admins: [],
    clientAdminFirstName: '',
    clientAdminLastName: '',
    clientAdminEmail: '',
    clientAdminPhone: null,
  };

  const handleSubmit = async (value: IClientFormValues) => {
    const selectedContacts = contacts.filter(contact =>
      value.admins.includes(contact.email)
    );
    const reformatedSelectedContacts: IAdminsForClientV2Body[] =
      selectedContacts.map((contact: IAssignableContactBody) => {
        return {
          email: contact.email,
          role_id: contact.role.id,
          first_name: contact.first_name,
          last_name: contact.last_name,
          phone: contact.phone,
        };
      });
    const clientAdmin = {
      email: value.clientAdminEmail,
      first_name: value.clientAdminFirstName,
      last_name: value.clientAdminLastName,
      phone: value.clientAdminPhone,
      role_id: undefined,
    };
    reformatedSelectedContacts.push(clientAdmin);

    const body: ICreateClientBody = {
      is_msp: false,
      msp_id: organizationId,
      name: value.name,
      address: {
        street: value.street,
        street2: value.street2,
        city: value.city,
        state: value.state,
        zip_code: '',
        country_code: value.country_code,
      },
      admins: reformatedSelectedContacts,
      margin: 0,
    };
    try {
      await dispatch(createClient(body)).unwrap();
      navigate(ROUTES.CLIENTS);
      dispatch(setModalProps({ radius: '18px', title: value.name }));
      dispatch(openModal());
      dispatch(setContent('AddClientConfirm'));
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperAddClient>
      <Title>Add a Client</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Grid container flexDirection="column">
              <Grid container flexDirection="row" spacing="4vw">
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="20px">
                    <SubTitle>Client Information</SubTitle>
                    <Grid item xs={12}>
                      <Field name="name">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Company Name"
                            variant="outlined"
                            placeholder="Enter Company Name"
                            fullWidth
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="street">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Address 1"
                            variant="outlined"
                            placeholder="Enter Address"
                            fullWidth
                            error={touched.street && Boolean(errors.street)}
                            helperText={touched.street && errors.street}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="street2">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Address 2"
                            variant="outlined"
                            placeholder="Enter Address"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container flexDirection="row" spacing="1vw">
                        <Grid item xs={8}>
                          <Field name="city">
                            {({ field }: FieldProps) => (
                              <TextField
                                {...field}
                                label="City"
                                variant="outlined"
                                placeholder="Enter City"
                                fullWidth
                                error={touched.city && Boolean(errors.city)}
                                helperText={touched.city && errors.city}
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          </Field>
                        </Grid>

                        <Grid item xs={4}>
                          <Field name="state">
                            {({ field }: FieldProps) => (
                              <TextField
                                {...field}
                                label="State"
                                variant="outlined"
                                placeholder="Enter State"
                                fullWidth
                                error={touched.state && Boolean(errors.state)}
                                helperText={touched.state && errors.state}
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel shrink>Select Country</InputLabel>
                        <Field name="country_code">
                          {({ field }: FieldProps) => (
                            <TextField
                              {...field}
                              select
                              label="Select Country"
                              variant="outlined"
                              fullWidth
                              error={
                                touched.country_code &&
                                Boolean(errors.country_code)
                              }
                              helperText={
                                touched.country_code && errors.country_code
                              }
                              InputLabelProps={{ shrink: true }}
                            >
                              {Array.isArray(countryOptions) &&
                                countryOptions.map(country => (
                                  <MenuItem
                                    key={country.value}
                                    value={country.value}
                                    style={MenuItemBox}
                                  >
                                    <MenuItemText>{country.label}</MenuItemText>
                                  </MenuItem>
                                ))}
                            </TextField>
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="admins">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Select Admins"
                            variant="outlined"
                            select
                            fullWidth
                            multiline
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                              multiple: true,
                              renderValue: (value: unknown) => {
                                const selected = value as string[];
                                return selected.join(', ');
                              },
                            }}
                          >
                            {Array.isArray(contacts) &&
                              contacts.map(contact => (
                                <MenuItem
                                  key={contact.email}
                                  value={contact.email}
                                  style={MenuItemBox}
                                >
                                  <MenuItemText>
                                    {ReformatContact([
                                      contact.first_name,
                                      contact.last_name,
                                      contact.email,
                                      contact.role.name,
                                    ])}
                                  </MenuItemText>
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="20px">
                    <SubTitle>Client Contact</SubTitle>
                    <Grid item xs={12}>
                      <Field name="clientAdminFirstName">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Client Admin First Name"
                            variant="outlined"
                            placeholder="Enter Client Admin First Name"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="clientAdminLastName">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Client Admin Last Name"
                            variant="outlined"
                            placeholder="Enter Client Admin Last Name"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="clientAdminEmail">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Client Admin Contact Email"
                            variant="outlined"
                            placeholder="Enter Client Admin Email"
                            fullWidth
                            error={
                              touched.clientAdminEmail &&
                              Boolean(errors.clientAdminEmail)
                            }
                            helperText={
                              touched.clientAdminEmail &&
                              errors.clientAdminEmail
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPhoneNumber
                        defaultCountry={'us'}
                        name="clientAdminPhone"
                        label="Client Admin Contact Phone"
                        variant="outlined"
                        value={values.clientAdminPhone}
                        onChange={value =>
                          setFieldValue('clientAdminPhone', value)
                        }
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} marginTop="50px">
                <FlatButton
                  width="150px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  Add Client
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {error && <Error>{error}</Error>}
    </WrapperAddClient>
  );
};

export default AddClient;
