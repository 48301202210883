import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainerMSP } from 'views';
import { SubTitle, Title, WrapperClientConfig } from './ClientsConfig.styled';
import { Box } from '@mui/material';
import { FlatButton } from 'ui';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getClients } from 'reduxStore/createProfile/createProfileSelectors';
import { useEffect } from 'react';
import { retrieveClients } from 'reduxStore/createProfile/createProfileOperations';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

const ClientsConfig = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clients = useAppSelector(getClients);

  useEffect(() => {
    dispatch(retrieveClients(null));

    const handleModalClose = () => {
      window.location.reload();
    };

    window.addEventListener('modalClose', handleModalClose);

    return () => {
      window.removeEventListener('modalClose', handleModalClose);
    };
  }, [dispatch]);

  const handleSubmit = () => {
    navigate(ROUTES.SERVICES_CONFIG);
  };

  const AddClient = () => {
    dispatch(
      setModalProps({
        radius: '18px',
      })
    );
    dispatch(openModal());
    dispatch(setContent('AddClientModal'));
  };

  const DeleteClient = (clientId: number) => {
    dispatch(
      setModalProps({
        radius: '18px',
        title: 'client',
        apiValue: clientId,
        anyIsStatement: 'true',
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteConfirmation'));
  };

  const handleBack = () => {
    navigate(ROUTES.TEAM_MEMBERS_CONFIG);
  };

  return (
    <WrapperClientConfig>
      <Box display="flex" flexDirection="column" gap="5px">
        <Title>Add Clients</Title>
        <SubTitle>Add your client's details to the dashboard</SubTitle>
      </Box>
      <FlatButton width="90%" variant="contained" onClick={AddClient}>
        Add Client
      </FlatButton>

      <Box display="flex" flexDirection="column" gap="10px">
        {Array.isArray(clients) &&
          clients.map(client => (
            <FlatButton
              width="90%"
              variant="contained"
              onClick={() => DeleteClient(client.id)}
            >
              {client.name}
            </FlatButton>
          ))}
      </Box>

      <ButtonsContainerMSP
        titleButton="Next"
        type="submit"
        onClickBack={handleBack}
        onClick={handleSubmit}
      />
    </WrapperClientConfig>
  );
};

export default ClientsConfig;
