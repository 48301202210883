import { IStatisticsCardData } from 'types/table.type';
import {
  DividerStatistics,
  IconAndLabel,
  StatInfo,
  SubTitle,
  Title,
  WrapperStatisticsCard,
} from './StatisticsCard.styled';

interface IStatisticsCard {
  title: string;
  subTitle: string;
  statistics: IStatisticsCardData[];
}

const StatisticsCard = ({ title, subTitle, statistics }: IStatisticsCard) => {
  return (
    <WrapperStatisticsCard>
      <Title> {title} </Title>
      <DividerStatistics />
      <SubTitle> {subTitle} </SubTitle>
      {statistics.map((stat, index) => (
        <StatInfo key={index}>
          <IconAndLabel>
            {stat.icon}
            {stat.label}
          </IconAndLabel>

          {stat.counter}
        </StatInfo>
      ))}
    </WrapperStatisticsCard>
  );
};

export default StatisticsCard;
