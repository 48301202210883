import React from 'react';
import { ProviderName, ProviderButtonWrapper } from './ProviderButton.styled';
import AddIcon from '@mui/icons-material/Add';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

interface IProviderButtonProps {
  variant:
    | 'google'
    | 'microsoft'
    | 'google_enterprise'
    | 'microsoft_enterprise';
  type?: 'button' | 'submit' | 'reset';
  isIcon?: 'true';
  width?: string;
  height?: string;
  isDisabled?: boolean;
  onClick?: (values?: any) => void;
  children: React.ReactNode | string;
}

const ProviderButton = ({
  variant,
  type,
  width,
  isIcon,
  height,
  isDisabled,
  onClick,
  children,
}: IProviderButtonProps) => {
  return (
    <ProviderButtonWrapper
      type={type}
      disabled={isDisabled}
      width={width}
      height={height}
      onClick={onClick}
      startIcon={isIcon && <AddIcon fontSize="large" />}
    >
      {variant === 'google' ? (
        <GoogleIcon fontSize="medium" />
      ) : variant === 'microsoft' ? (
        <MicrosoftIcon fontSize="medium" />
      ) : variant === 'google_enterprise' ? (
        <GoogleIcon fontSize="medium" color="secondary" />
      ) : variant === 'microsoft_enterprise' ? (
        <MicrosoftIcon fontSize="medium" color="secondary" />
      ) : (
        <></>
      )}
      <ProviderName>{children}</ProviderName>
    </ProviderButtonWrapper>
  );
};

export default ProviderButton;
