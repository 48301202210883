import { ROUTES } from 'routes/routes.const';
import { MainNavigationMenu } from 'ui/menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import { useAppSelector } from 'hooks';
import { getSelectedOrgName } from 'reduxStore/toolHuntress/toolHuntressSelectors';

const NavigationHuntress = () => {
  const selectedOrgName = useAppSelector(getSelectedOrgName);
  const navOptions = [
    {
      title: 'Dashboard',
      url: ROUTES.HUNTRESS_DASHBOARD,
      icon: <HomeOutlinedIcon></HomeOutlinedIcon>,
    },
    {
      title: 'EDR',
      url: ROUTES.HUNTRESS_EDR,
      icon: <AssessmentOutlinedIcon></AssessmentOutlinedIcon>,
    },
    {
      title: 'ITDR',
      url: ROUTES.HUNTRESS_ITDR,
      icon: <ShieldOutlinedIcon></ShieldOutlinedIcon>,
    },
  ];

  return <MainNavigationMenu options={navOptions} orgName={selectedOrgName} />;
};

export default NavigationHuntress;
