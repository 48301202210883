import {
  Amount,
  SubTitle,
  Title,
  WrapperBottom,
  WrapperGeneralStatistics,
  WrapperTop,
} from './GeneralStatistics.styled';
import {
  IClientStatisticBody,
  IReceiveContactBody,
  ITool,
} from 'types/api.type';
import { RoundButton, ActionButton } from 'ui';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

interface IGeneralStatisticsProps {
  onClickAdd?: () => void;
  onClickViewAll?: () => void;
  title: 'Team Members' | 'Clients' | 'Services';
  data: ITool[] | IClientStatisticBody[] | IReceiveContactBody[] | null;
  width?: string;
  height?: string;
  isactive?: 'true' | 'false';
}

const GeneralStatistics = ({
  onClickAdd,
  onClickViewAll,
  title,
  data,
  width,
  height,
  isactive,
}: IGeneralStatisticsProps) => {
  return (
    <WrapperGeneralStatistics width={width} height={height} isactive={isactive}>
      <Box gap="5px">
        <WrapperTop>
          <Title>{title}</Title>
          <RoundButton
            width="52px"
            height="52px"
            type="submit"
            variant="contained"
            onClick={onClickAdd}
          >
            <AddIcon fontSize="medium" />
          </RoundButton>
        </WrapperTop>
        <Amount>{data?.length}</Amount>
      </Box>
      <Box gap="5px">
        <SubTitle>Recent</SubTitle>
        <WrapperBottom>
          <ActionButton onClick={onClickViewAll} width="100%">
            View All
          </ActionButton>
        </WrapperBottom>
      </Box>
    </WrapperGeneralStatistics>
  );
};
export default GeneralStatistics;
