import { useAppDispatch, useAppSelector } from 'hooks';
import {
  TitleActive,
  TitleResolved,
  WrapperEDR,
  WrapperIncidentStatistic,
} from './HuntressEDRPage.styled';
import {
  getHuntressGeneralStatistics,
  getSelectedOrgId,
} from 'reduxStore/toolHuntress/toolHuntressSelectors';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/toolHuntress/toolHuntressOperations';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { StatisticsCard, StatusBox } from 'ui';
import { IStatisticsCardData } from 'types/table.type';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const HuntressEDRPage = () => {
  const dispatch = useAppDispatch();
  const generalStatistics = useAppSelector(getHuntressGeneralStatistics);
  const selectedOrgId = useAppSelector(getSelectedOrgId);

  const sumOfActiveIncidents =
    (generalStatistics?.incidents.sent.crit_count ?? 0) +
    (generalStatistics?.incidents.sent.high_count ?? 0) +
    (generalStatistics?.incidents.sent.low_count ?? 0);

  const sumOfDefenders =
    (generalStatistics?.defenders.protected_count ?? 0) +
    (generalStatistics?.defenders.incompatible_count ?? 0) +
    (generalStatistics?.defenders.unmanaged_count ?? 0) +
    (generalStatistics?.defenders.healthy_count ?? 0) +
    (generalStatistics?.defenders.unhealthy_count ?? 0);

  const reformatedAgents: IStatisticsCardData[] = [
    {
      icon: <ShieldOutlinedIcon fontSize="small" />,
      label: 'Protected',
      counter: generalStatistics?.agents.protected_count,
    },
    {
      icon: <FmdBadOutlinedIcon fontSize="small" />,
      label: 'Unresponsive',
      counter: generalStatistics?.agents.unresponsive_count,
    },
    {
      icon: <AccessTimeOutlinedIcon fontSize="small" />,
      label: 'Outdated',
      counter: generalStatistics?.agents.outdated_count,
    },
    {
      icon: <LockOutlinedIcon fontSize="small" />,
      label: 'Isolated',
      counter: generalStatistics?.agents.isolated_count,
    },
  ];

  useEffect(() => {
    dispatch(retrieveHuntressGeneralStatistics(selectedOrgId));
  }, [dispatch, selectedOrgId]);

  return (
    <WrapperEDR>
      <WrapperIncidentStatistic>
        <TitleActive>Active Incidents ({sumOfActiveIncidents})</TitleActive>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="20px"
        >
          <StatusBox status="critical" isstarticon="true" width="150px">
            {generalStatistics?.incidents.sent.crit_count}
          </StatusBox>
          <StatusBox status="high" isstarticon="true" width="150px">
            {generalStatistics?.incidents.sent.high_count}
          </StatusBox>
          <StatusBox status="low" isstarticon="true" width="150px">
            {generalStatistics?.incidents.sent.low_count}
          </StatusBox>
        </Box>
      </WrapperIncidentStatistic>
      <WrapperIncidentStatistic>
        <TitleResolved>
          Resolved Incidents ({generalStatistics?.incidents.closed.yearly_count}
          )
        </TitleResolved>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="20px"
        >
          <StatusBox status="resolved" width="150px">
            {generalStatistics?.incidents.closed.monthly_count} Month
          </StatusBox>
          <StatusBox status="resolved" width="150px">
            {generalStatistics?.incidents.closed.quarterly_count} Quarter
          </StatusBox>
          <StatusBox status="resolved" width="150px">
            {generalStatistics?.incidents.closed.yearly_count} Year
          </StatusBox>
        </Box>
      </WrapperIncidentStatistic>
      <StatisticsCard
        title="Agent Status"
        subTitle={`Win Defender Firewall is on (${sumOfDefenders}) Hosts `}
        statistics={reformatedAgents}
      />
    </WrapperEDR>
  );
};

export default HuntressEDRPage;
