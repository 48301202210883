import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

interface IStatusBoxProps extends BoxProps {
  status?: 'critical' | 'high' | 'low' | 'resolved';
  width?: string;
}

export const StyledStatusBox = styled(Box)<IStatusBoxProps>(
  ({ theme, status, width }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: width ? width : 'fit-content',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '-0.351px',
    textTransform: 'capitalize',
    border: '2px solid',
    borderRadius: status === 'resolved' ? '0px' : '10px',
    borderColor:
      status === 'low'
        ? theme.palette.info.dark
        : status === 'high'
          ? theme.palette.warning.dark
          : status === 'critical'
            ? theme.palette.red.main
            : status === 'resolved'
              ? theme.palette.success.dark
              : theme.palette.secondary.dark,
    padding: '3px 10px',
    gap: '4px',
    color:
      status === 'low'
        ? theme.palette.info.dark
        : status === 'high'
          ? theme.palette.warning.dark
          : status === 'critical'
            ? theme.palette.red.main
            : theme.palette.secondary.dark,
    background: theme.palette.background.default,
  })
);

export const TextBox = styled(Box)<IStatusBoxProps>(({ width }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
}));
