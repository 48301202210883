import { ROUTES } from '../routes/routes.const';

export interface IStepNewProfile {
  path: string;
  step: number;
}

export const STEPS_NEW_PROFILE: IStepNewProfile[] = [
  {
    path: ROUTES.ORGANIZATION_PROFILE,
    step: 1,
  },
  {
    path: ROUTES.TEAM_MEMBERS_CONFIG,
    step: 2,
  },
  { path: ROUTES.CLIENTS_CONFIG, step: 3 },
  { path: ROUTES.SERVICES_CONFIG, step: 4 },
];
