export const ROUTES = {
  DASHBOARD: '/',
  SIGNIN: '/login',

  //right now does not used
  REDIRECT: 'redirect',
  PROCESS_AUTH: '/process-auth',

  //TODO: it is a page that has wireframe for sys prog, not sure what to do with it
  SET_UP: '/set_up',

  //steps in account creation
  ORGANIZATION_PROFILE: '/organization_profile',
  CLIENTS_CONFIG: '/clients_config',
  SERVICES_CONFIG: '/services_config',
  TEAM_MEMBERS_CONFIG: '/team_members_config',

  ADD_OR_UPDATE_TEAM_MEMBER: '/add_or_update_team_member',
  DELETE_TEAM_MEMBER: '/delete_team_member',
  TEAM_MEMBERS: '/team_members',

  ADD_CLIENT: '/add_client',
  DELETE_CLIENT: '/delete_client',
  CLIENTS: '/clients',

  ADD_SERVICE: '/add_service',
  DELETE_INTEGRATION: '/delete_integration',
  SERVICES: '/services',

  SERVICE_PAGE: '/service_page',

  TICKETS: '/tickets',
  AUDIT_LOGS: '/audit_logs',

  // --- HUNTRESS PAGES ---
  HUNTRESS_DASHBOARD: '/huntress_dashboard',
  // --- Huntress Dashboard Children ---
  HUNTRESS_DASHBOARD_AGENTS: '/huntress_dashboard/agents',
  HUNTRESS_DASHBOARD_INCIDENTS: '/huntress_dashboard/incidents',
  HUNTRESS_DASHBOARD_REPORTS: '/huntress_dashboard/reports',

  HUNTRESS_EDR: '/huntress_edr',
  HUNTRESS_ITDR: '/huntress_itdr',
};

export const CREATE_ORG_ROUTES = [ROUTES.ORGANIZATION_PROFILE];
