//OKTA VERIFICATION
export function save0AuthState(state: string) {
  sessionStorage.setItem('oauth_state', state);
}

export function get0AuthState() {
  return sessionStorage.getItem('oauth_state');
}

export function clear0AuthState() {
  sessionStorage.removeItem('oauth_state');
}
