import { RootState } from 'reduxStore/store';

//TODO: Revise selectors and delete unused

const getIsCreatingOrganization = (state: RootState) =>
  state.createProfile.creatingOrganization;
const getOrganizationLevel = (state: RootState) =>
  state.createProfile.organizationLevel;

// create / update organization
const getOrganizationId = (state: RootState) =>
  state.createProfile.organizationID;
const getOrganizationValues = (state: RootState) =>
  state.createProfile.organizationValues;

// import users
const getUsersListImported = (state: RootState) =>
  state.createProfile.usersListImported;

const getCreateProfileError = (state: RootState) => state.createProfile.error;

const getClouds = (state: RootState) => state.createProfile.clouds;

const getRoles = (state: RootState) => state.createProfile.roles;

const getContacts = (state: RootState) => state.createProfile.contacts;

const getAssignableContacts = (state: RootState) =>
  state.createProfile.assignableContacts;

const getClients = (state: RootState) => state.createProfile.clients;

const getClientsRef = (state: RootState) => state.createProfile.clientsAsRefs;

const getTools = (state: RootState) => state.createProfile.tools;

const getToolsRef = (state: RootState) => state.createProfile.toolsAsRefs;

const getToolOrgs = (state: RootState) => state.createProfile.toolOrgs;

const getTeamMemberToUpdate = (state: RootState) =>
  state.createProfile.teamMemberToUpdate;

export {
  getIsCreatingOrganization,
  getOrganizationLevel,
  getOrganizationId,
  getOrganizationValues,
  getUsersListImported,
  getCreateProfileError,
  getClouds,
  getRoles,
  getContacts,
  getAssignableContacts,
  getClients,
  getClientsRef,
  getTools,
  getToolsRef,
  getToolOrgs,
  getTeamMemberToUpdate,
};
