import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainerMSP } from 'views';

const ServicesConfig = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(ROUTES.DASHBOARD, { state: { from: 'createMainOrganization' } });
  };

  const handleBack = () => {
    navigate(ROUTES.CLIENTS_CONFIG);
  };

  return (
    <ButtonsContainerMSP
      titleButton="Next"
      type="submit"
      onClickBack={handleBack}
      onClick={handleSubmit}
    />
  );
};

export default ServicesConfig;
