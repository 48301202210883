import { Outlet } from 'react-router';
import { Stack } from '@mui/material';
import { LogicallyContainer } from './MainLayoutPage.styled';
import { SideBarLogically } from 'views';

const MainLayoutPage = () => {
  return (
    <Stack direction="row" width="100vw">
      <SideBarLogically />
      <LogicallyContainer>
        <Outlet />
      </LogicallyContainer>
    </Stack>
  );
};

export default MainLayoutPage;
