import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

export const StyledHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '22px 22px 22px 86px',
  borderRadius: '18px',
  marginBottom: '21px',
  background: theme.palette.background.paper,
}));

export const StyledProgressWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '80px',
  height: '80px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '18px',
  background: theme.palette.background.paper,
  margin: '0px',
}));
