import { Box, BoxProps, styled } from '@mui/material';

export const WrapperGeneral = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '50px',
}));

export const MenuInfoBoxes = styled(Box)<BoxProps>(() => ({
  width: '80vw',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
