import {
  StyledBox,
  StyledIconButton,
  StyledOutputIcon,
  StyledTypography,
} from './ButtonLogout.styled';
import LogoutIcon from '@mui/icons-material/Logout';
import { IOrganizationsType } from 'types';
import { COMPANY, MSP } from 'constants/organizationsType';

interface IButtonLogoutProps extends IOrganizationsType {
  onClick: () => void;
  inTool?: 'Huntress' | 'Kaseya' | 'NinjaOne';
}

const ButtonLogout = ({ type, inTool, onClick }: IButtonLogoutProps) => {
  const buttonText = inTool
    ? `log out from ${inTool}`
    : type === COMPANY
      ? 'log out'
      : type === MSP
        ? 'Sign Out'
        : 'threads';
  return (
    <StyledBox buttontype={type} onClick={onClick}>
      <StyledIconButton buttontype={type}>
        {type === MSP ? <StyledOutputIcon /> : <LogoutIcon color="error" />}
        <StyledTypography buttontype={type}>{buttonText}</StyledTypography>
      </StyledIconButton>
    </StyledBox>
  );
};

export default ButtonLogout;
