import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import {
  Container,
  NavContainer,
  WrapperSidebar,
} from './SideBarHuntress.styled';
import { ButtonLogout, NavigationHuntress } from 'ui';
import { COMPANY } from 'constants/organizationsType';

const SideBarHuntress = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate(ROUTES.SET_UP);
  };

  return (
    <Container>
      <WrapperSidebar>
        <NavContainer>
          <NavigationHuntress />
        </NavContainer>
        <ButtonLogout onClick={handleLogout} type={COMPANY} inTool="Huntress" />
      </WrapperSidebar>
    </Container>
  );
};

export default SideBarHuntress;
