import { styled } from '@mui/material';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { POPPINS } from 'constants/fonts';

export const StyledTextField = styled(TextField)<StandardTextFieldProps>(
  ({ theme }) => ({
    width: '100%',
    height: '57px',
    borderRadius: '43px',
    background: theme.palette.common.white,
    border: 'transparent',
    boxShadow:
      '0px 50px 100px -20px rgba(17, 12, 46, 0.15), 0px 30px 60px -30px rgba(0, 0, 0, 0.30)',
    '& .MuiOutlinedInput-root': {
      paddingLeft: '31px',
      '& input': {
        color: theme.palette.secondary.main,
      },
      '&:focus + .MuiInputLabel-root': {
        opacity: '1',
      },
      '& fieldset': {
        border: 'none',
        borderRadius: '43px',
      },
      '&:hover fieldset': {
        border: `2px solid ${theme.palette.info.main}`,
      },
      '&.Mui-focused fieldset': {
        border: `2px solid ${theme.palette.info.main}`,
      },
    },
    '& .MuiOutlinedInput-root input': {
      color: '#878787',
      fontFamily: POPPINS,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '30.8px',
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      fontSize: '22px',
      transform: 'translate(22px, -8px) scale(0.72)',
      color: theme.palette.secondary.main,
    },
  })
);
