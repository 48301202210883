import { ITableColumn } from 'types';

export const TEAM_MEMBERS_TABLE: ITableColumn[] = [
  { label: 'First Name', key: 'first_name', disableSorting: false },
  { label: 'Last Name', key: 'last_name', disableSorting: false },
  { label: 'Phone Number', key: 'phone', disableSorting: false },
  { label: 'E-mail', key: 'email', disableSorting: false },
  { label: 'Role', key: 'role', disableSorting: false },
  // { label: 'Administrator', value: 'switch_admin', disableSorting: false },
];

export const CLIENTS_TABLE: ITableColumn[] = [
  { label: 'Client Name', key: 'name', disableSorting: false },
  { label: 'Tools', key: 'tool_count', disableSorting: false },
  { label: 'Devices', key: 'dev_count', disableSorting: false },
  { label: 'Admins', key: 'admin_count', disableSorting: false },
  { label: 'Alerts', key: 'alert_count', disableSorting: false },
];

export const SERVICES_TABLE: ITableColumn[] = [
  { label: 'Service Name', key: 'service_name', disableSorting: false },
  { label: 'Client Count', key: 'client_count', disableSorting: false },
  { label: 'Devices', key: 'devices', disableSorting: false },
  { label: 'Status', key: 'status', disableSorting: false },
  { label: 'Is Connected', key: 'is_connected', disableSorting: false },
];

export const TOOL_CLIENTS_TABLE: ITableColumn[] = [
  { label: 'Client Name', key: 'name', disableSorting: false },
  { label: 'Devices', key: 'dev_count', disableSorting: false },
  { label: 'Alerts', key: 'alert_count', disableSorting: false },
];

export const HUNTRESS_AGENTS_TABLE: ITableColumn[] = [
  { label: 'Name', key: 'name', disableSorting: false },
  { label: 'OS Name', key: 'os_name', disableSorting: false },
  { label: 'EDR', key: 'edr_version', disableSorting: false },
  { label: 'External IP', key: 'ext_ip', disableSorting: false },
  { label: 'Internal IP', key: 'int_ip', disableSorting: false },
  { label: 'Registered', key: 'registered_at', disableSorting: false },
  { label: 'Last Seen', key: 'last_seen_at', disableSorting: false },
];

export const HUNTRESS_INCIDENTS_TABLE: ITableColumn[] = [
  { label: 'Host Name', key: 'entity', disableSorting: false },
  { label: 'Sent At', key: 'sent_at', disableSorting: false },
  { label: 'Platform', key: 'platform', disableSorting: false },
  { label: 'Actions', key: 'actions', disableSorting: false },
  { label: 'Remediations', key: 'remediations', disableSorting: false },
  { label: 'Severity', key: 'severity', disableSorting: false },
  { label: 'Status', key: 'status', disableSorting: false },
];

export const HUNTRESS_REPORTS_TABLE: ITableColumn[] = [
  { label: 'Type', key: 'type', disableSorting: false },
  { label: 'Date Range', key: 'date_range', disableSorting: false },
];
