import {
  Box,
  BoxProps,
  Divider,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperStatisticsCard = styled(Box)<BoxProps>(() => ({
  height: 'fit-content',
  width: '45vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '33px',
  border: '1px solid',
  borderColor: COLORS.black,
  padding: '30px',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
}));

export const SubTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '-0.450px',
}));

export const Info = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '-0.351px',
}));

export const DividerStatistics = styled(Divider)(() => ({
  width: '100%',
  height: '1px',
  borderColor: COLORS.black,
}));

export const StatInfo = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const IconAndLabel = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
}));
