import { save0AuthState } from './sessionStorageHelpers';
//import { ROUTES } from '../routes/routes.const';

export const generateState = () => {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

export const generateAuthUrl = (provider: 'okta' | 'google' | 'microsoft') => {
  const domain = process.env.REACT_APP_OKTA_DOMAIN;
  const audience = process.env.REACT_APP_OKTA_AUDIENCE;
  const clientId =
    provider === 'okta'
      ? process.env.REACT_APP_OKTA_CLIENT_ID
      : provider === 'google'
        ? process.env.REACT_APP_GOOGLE_CLIENT_ID
        : ' ';

  const redirectUri = encodeURIComponent(
    `${process.env.REACT_APP_REDIRECT_URL}`
  );

  const scope = encodeURIComponent('openid profile email');

  if (provider === 'okta') {
    const state = encodeURIComponent(generateState());
    save0AuthState(state);
    return (
      `https://${domain}/authorize?` +
      `response_type=code&` +
      `client_id=${clientId}&` +
      `redirect_uri=${redirectUri}&` +
      `scope=${scope}&` +
      `audience=${audience}&` +
      `state=${state}`
    );
  }
  if (provider === 'google') {
    return (
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `redirect_uri=${redirectUri}&` +
      `response_type=code&` +
      `client_id=${clientId}&` +
      `scope=${scope}&` +
      `&prompt=select_account`
    );
  }
};

export const getPopupWindowParams = (width: number, height: number) => {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;
  const adjustedWidth = Math.min(width, window.screen.width);
  const adjustedHeight = Math.min(height, window.screen.height);
  return `width=${adjustedWidth},height=${adjustedHeight},top=${top},left=${left}`;
};
