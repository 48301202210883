import { Outlet } from 'react-router';
import {
  StyledContainer,
  StyledBox,
  StyledWrapper,
  StyledWrapperWhite,
} from './CreateProfileLayoutPage.styled';
import { Box } from '@mui/material';
import { StepperCreateProfile } from 'ui';

const CreateProfileLayoutPage = () => {
  return (
    <StyledBox overflow="hidden">
      <StyledContainer>
        <Box>
          <StepperCreateProfile />
          <StyledWrapper>
            <StyledWrapperWhite>
              <Outlet />
            </StyledWrapperWhite>
          </StyledWrapper>
        </Box>
      </StyledContainer>
    </StyledBox>
  );
};

export default CreateProfileLayoutPage;
