import React from 'react';
import { StyledRoundButton } from './RoundButton.styled';
import { FormikValues, useFormikContext } from 'formik';

interface IRoundButtonProps {
  variant?: 'contained' | 'outlined';
  type?: 'button' | 'submit' | 'reset';
  width?: string;
  height?: string;
  isDisabled?: boolean;
  isAPI?: 'true';
  onClick?: (values?: any) => void;
  children?: React.ReactNode | string;
}

const RoundButton = ({
  variant,
  type,
  isAPI,
  width,
  height,
  isDisabled,
  onClick,
  children,
}: IRoundButtonProps) => {
  const formikContext = useFormikContext<FormikValues>();
  const onClickHandler = () => {
    if (isAPI && formikContext) {
      onClick?.(formikContext.values);
    } else {
      onClick?.();
    }
  };
  return (
    <StyledRoundButton
      variant={variant}
      type={type}
      height={height}
      width={width}
      disabled={isDisabled}
      onClick={onClickHandler}
    >
      {children}
    </StyledRoundButton>
  );
};

export default RoundButton;
