import { useAppDispatch, useAppSelector } from 'hooks';
import { Wrapper } from './HuntressReports.styled';
import {
  //getHuntressReportRefs,
  getHuntressReports,
  getSelectedOrgId,
} from 'reduxStore/toolHuntress/toolHuntressSelectors';
import { useEffect } from 'react';
import {
  retrieveHuntressReports,
  retrieveHuntressReportsRefs,
} from 'reduxStore/toolHuntress/toolHuntressOperations';
import { IHuntressReportsBody } from 'types/huntress.type';
import { TableBasis } from 'ui';
import { HUNTRESS_REPORTS_TABLE } from 'constants/tablesHead';

const HuntressReports = () => {
  const dispatch = useAppDispatch();
  //NEEDED FOR FILTERS
  //const reportRefs = useAppSelector(getHuntressReportRefs);
  const selectedOrgId = useAppSelector(getSelectedOrgId);
  const reports = useAppSelector(getHuntressReports);

  const reformatedReports = reports.map(report => {
    return {
      type: report.type.name,
      date_range: report.start + ' - ' + report.finish,
    };
  });

  useEffect(() => {
    const body: IHuntressReportsBody = {
      org_id: selectedOrgId,
      type_id: null,
    };
    dispatch(retrieveHuntressReportsRefs(selectedOrgId));
    dispatch(retrieveHuntressReports(body));
  }, [dispatch, selectedOrgId]);

  return (
    <Wrapper>
      <TableBasis
        title="Reports"
        columns={HUNTRESS_REPORTS_TABLE}
        tableData={reformatedReports}
      ></TableBasis>
    </Wrapper>
  );
};

export default HuntressReports;
