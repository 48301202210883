import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface StyledButtonProps extends ButtonProps {
  width?: string;
  height?: string;
}

export const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, width, height }) => ({
    display: 'flex',
    width: width ? width : 'fit-content',
    height: height ? height : 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2px',
    padding: '7px 18px',

    borderRadius: '21px',
    border: '1px solid',
    borderColor: COLORS.superSilver,
    background: theme.palette.silver.dark,
    color: theme.palette.primary.dark,

    fontFamily: INTER,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.264px',

    // default hover styles
    '&:hover': {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  })
);
