import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface IRoundButtonProps extends ButtonProps {
  variant?: 'contained' | 'outlined';
  width?: string;
  height?: string;
}

export const StyledRoundButton = styled(Button)<IRoundButtonProps>(
  ({ theme, variant, width, height }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: width,
    height,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    borderRadius: '50%',
    border: 'none',
    color:
      variant === 'contained'
        ? theme.palette.common.white
        : theme.palette.secondary.light,
    background:
      variant === 'contained'
        ? theme.palette.primary.main
        : theme.palette.action.disabled,
    '&:hover': {
      border: 'none',
      color:
        variant === 'contained'
          ? theme.palette.common.white
          : theme.palette.secondary.light,
      background:
        variant === 'contained'
          ? theme.palette.primary.main
          : theme.palette.action.disabled,
    },
  })
);
