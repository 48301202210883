import { useState, SyntheticEvent, useEffect } from 'react';
import { useLocation } from 'react-router';
import Tab from '@mui/material/Tab';
import {
  StyledTabs,
  StyledLink,
  StyledBox,
  CompanyName,
} from './MainNavigationMenu.styled';
import { IAdminType, ITabMenuProps } from 'types';
import { initiateTabValueForNavTabs } from 'utils';
import { Logo } from 'ui';
import { Box } from '@mui/material';

interface INavTabsProps extends IAdminType {
  options: ITabMenuProps[];
  orgName: string;
}

const MainNavigationMenu = ({ options, type, orgName }: INavTabsProps) => {
  const { pathname } = useLocation();

  const initiateValue = () =>
    initiateTabValueForNavTabs(options, pathname, true);

  const [value, setValue] = useState(initiateValue);

  useEffect(() => {
    if (pathname !== '/') {
      const parentPath = pathname.split('/').filter(String)[0];
      const parentIndex = options.findIndex(i => i.url === `/${parentPath}`);
      setValue(parentIndex !== -1 ? parentIndex : prevState => prevState);
    }
    if (pathname === '/') {
      setValue(0);
    }
  }, [pathname, options]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledBox>
      <Logo />
      <Box gap="5px">
        <CompanyName>{orgName}</CompanyName>
        <StyledTabs type={type} value={value} onChange={handleChange}>
          {options.map(({ title, url, icon }, index) => (
            <Tab
              key={`navigation-tab-${index}`}
              type={type}
              iconPosition="start"
              icon={icon}
              label={title}
              component={StyledLink}
              to={url}
            />
          ))}
        </StyledTabs>
      </Box>
    </StyledBox>
  );
};

export default MainNavigationMenu;
