import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/auth';
import { AUTH_ERROR } from 'constants/errors';
import { isServerError } from 'utils/apiHelpers';
import { ILogInBody } from 'types';

const signIn = createAsyncThunk(
  'auth/signIn',
  async (body: ILogInBody, { rejectWithValue }) => {
    try {
      const result = await api.postSignIn(body);
      return result;
    } catch (error: any) {
      const status = error.response.status;
      const reason = error.response.data.reason;
      const message = error.response.data.message;

      console.error('Auth error: ', message ?? error);

      if (isServerError(status)) {
        return rejectWithValue({
          title: 'System Error!',
          text: `HTTP ${status}`,
        });
      }

      if (status === 404) {
        return rejectWithValue({
          title: `System Error! ${status}`,
          text:
            error.response.data?.error?.description ??
            'The requested resource could not be found.',
        });
      }

      if (reason === 'user' || reason === 'system') {
        return rejectWithValue({
          title: reason === 'user' ? 'Error!' : 'System Error!',
          text: message,
        });
      }

      return rejectWithValue({ title: AUTH_ERROR, text: '' });
    }
  }
);

export { signIn };
