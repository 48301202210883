import { ReactNode } from 'react';
import { StyledStatusBox, TextBox } from './StatusBox.styled';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';

interface IStatusBoxProps {
  status?: 'critical' | 'high' | 'low' | 'resolved';
  isstarticon?: 'true';
  width?: string;
  children: ReactNode | string;
}

const StatusBox = ({
  status,
  isstarticon,
  width,
  children,
}: IStatusBoxProps) => {
  return (
    <StyledStatusBox status={status} width={width}>
      {status === 'low' && isstarticon ? (
        <BuildOutlinedIcon fontSize="small" />
      ) : status === 'high' && isstarticon ? (
        <WarningAmberOutlinedIcon fontSize="small" />
      ) : status === 'critical' && isstarticon ? (
        <FmdBadOutlinedIcon fontSize="small" />
      ) : undefined}
      {status === 'resolved' ? (
        <>{children}</>
      ) : (
        <>
          <TextBox>{children}</TextBox>
          <TextBox>{status}</TextBox>
        </>
      )}
    </StyledStatusBox>
  );
};

export default StatusBox;
