import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/toolHuntress/toolHuntressOperations';
import {
  getHuntressGeneralStatistics,
  getSelectedOrgId,
} from 'reduxStore/toolHuntress/toolHuntressSelectors';
import {
  IncidentsCountersWrapper,
  TitleActive,
  WrapperITDR,
} from './HuntressITDRPage.styled';
import { StatusBox } from 'ui';

const HuntressITDRPage = () => {
  const dispatch = useAppDispatch();
  const generalStatistics = useAppSelector(getHuntressGeneralStatistics);
  const selectedOrgId = useAppSelector(getSelectedOrgId);

  const sumOfActiveIncidents =
    (generalStatistics?.incidents.sent.crit_count ?? 0) +
    (generalStatistics?.incidents.sent.high_count ?? 0) +
    (generalStatistics?.incidents.sent.low_count ?? 0);

  useEffect(() => {
    dispatch(retrieveHuntressGeneralStatistics(selectedOrgId));
  }, [dispatch, selectedOrgId]);

  return (
    <WrapperITDR>
      <TitleActive>Active Incidents ({sumOfActiveIncidents})</TitleActive>
      <IncidentsCountersWrapper>
        <StatusBox status="critical" isstarticon="true" width="150px">
          {generalStatistics?.incidents.sent.crit_count}
        </StatusBox>
        <StatusBox status="high" isstarticon="true" width="150px">
          {generalStatistics?.incidents.sent.high_count}
        </StatusBox>
        <StatusBox status="low" isstarticon="true" width="150px">
          {generalStatistics?.incidents.sent.low_count}
        </StatusBox>
      </IncidentsCountersWrapper>
    </WrapperITDR>
  );
};

export default HuntressITDRPage;
