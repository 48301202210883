import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'services/api/toolHuntress';
import {
  IHuntressIncidentsBody,
  IHuntressReportsBody,
} from 'types/huntress.type';

const retrieveHuntressGeneralStatistics = createAsyncThunk(
  'toolHuntress/retrieveHuntressGeneralStatistics',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressGeneralStatistics(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress statistics error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressAgents = createAsyncThunk(
  'toolHuntress/retrieveHuntressAgents',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressAgents(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress agents error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressIncidentsRefs = createAsyncThunk(
  'toolHuntress/retrieveHuntressIncidentsRef',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressIncidentsRefs(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress incident refs error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressIncidents = createAsyncThunk(
  'toolHuntress/retrieveHuntressIncidents',
  async (body: IHuntressIncidentsBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressIncidents(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress incidents error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressReportsRefs = createAsyncThunk(
  'toolHuntress/retrieveHuntressReportsRefs',
  async (orgId: number, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressReportsRefs(orgId);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress report refs error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const retrieveHuntressReports = createAsyncThunk(
  'toolHuntress/retrieveHuntressReports',
  async (body: IHuntressReportsBody, { rejectWithValue }) => {
    try {
      const result = await api.postRetrieveHuntressReports(body);
      return result;
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        'retrieving Huntress reports error. Please try again';
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export {
  retrieveHuntressGeneralStatistics,
  retrieveHuntressAgents,
  retrieveHuntressIncidentsRefs,
  retrieveHuntressIncidents,
  retrieveHuntressReportsRefs,
  retrieveHuntressReports,
};
