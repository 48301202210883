import { Stack } from '@mui/material';
import { SideBarHuntress } from 'views';
import { HuntressContainer } from './HuntressLayoutPage.styled';
import { Outlet } from 'react-router';

const HuntressLayoutPage = () => {
  return (
    <Stack direction="row" width="100vw">
      <SideBarHuntress />
      <HuntressContainer>
        <Outlet />
      </HuntressContainer>
    </Stack>
  );
};

export default HuntressLayoutPage;
