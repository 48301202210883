import { ButtonLogout, NavigationLogically } from 'ui';
import {
  Container,
  WrapperSidebar,
  NavContainer,
} from './SideBarLogically.styled';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';
import { COMPANY } from 'constants/organizationsType';

const SideBarLogically = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(getIsAuth);

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
    }
  };

  return (
    <Container>
      <WrapperSidebar>
        <NavContainer>
          <NavigationLogically />
        </NavContainer>
        <ButtonLogout onClick={handleLogout} type={COMPANY} />
      </WrapperSidebar>
    </Container>
  );
};

export default SideBarLogically;
