import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const Wrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '33px',
  width: '80vw',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '26px',
  fontWeight: 500,
  lineHeight: '34px',
  letterSpacing: '-0.358px',
}));

export const IncidentsCountersWrapper = styled(Box)<BoxProps>(() => ({
  width: '60vw',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const MenusWrapper = styled(Box)<BoxProps>(() => ({
  padding: '30px 0px',
  width: '80vw',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
