import { useAppDispatch, useAppSelector } from 'hooks';
import {
  SectionTitle,
  WrapperGeneral,
  WrapperServices,
} from './SetUpPage.styled';
import { useEffect } from 'react';
import {
  retrieveClientsRef,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getClientsRef,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { StepAction } from 'ui';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const SetUpPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clientsAsRefs = useAppSelector(getClientsRef);
  const tools = useAppSelector(getTools);

  const connectedTools = tools.filter(
    tool =>
      (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
      (tool.clients_count !== null && tool.clients_count !== 0)
  );
  const disconnectedTools = tools.filter(
    tool => !connectedTools.some(connectedTool => connectedTool.id === tool.id)
  );

  useEffect(() => {
    dispatch(retrieveClientsRef(null));
    dispatch(retrieveTools(null));
  }, [dispatch]);

  const handleOpenServicePage = (serviceId: number) => {
    navigate(ROUTES.SERVICE_PAGE, {
      state: { toolId: serviceId },
    });
  };

  const handleDisconnectedServiceClick = (toolId: number) => {
    navigate(ROUTES.ADD_SERVICE, {
      state: { toolId: toolId },
    });
  };

  return (
    <WrapperGeneral>
      <WrapperServices>
        {Array.isArray(connectedTools) &&
          connectedTools.map((connectedTool, index) => (
            <StepAction
              key={index}
              width="20vw"
              height="20vh"
              onClick={() => handleOpenServicePage(connectedTool.id)}
            >
              {connectedTool.name} <br /> {connectedTool.clients_count}/
              {clientsAsRefs.length} Clients
            </StepAction>
          ))}
      </WrapperServices>
      {disconnectedTools.length > 0 && (
        <>
          <SectionTitle>Also Available:</SectionTitle>
          <WrapperServices>
            {Array.isArray(disconnectedTools) &&
              disconnectedTools.map((disconnectedTool, index) => (
                <StepAction
                  key={index}
                  width="20vw"
                  height="20vh"
                  isdisconnected="true"
                  onClick={() =>
                    handleDisconnectedServiceClick(disconnectedTool.id)
                  }
                >
                  {disconnectedTool.name}
                </StepAction>
              ))}
          </WrapperServices>
        </>
      )}
    </WrapperGeneral>
  );
};

export default SetUpPage;
