import {
  GeneralStatistics,
  IconButton,
  Search,
  SelectActions,
  TableBasis,
} from 'ui';
import { WrapperGeneral, MenuInfoBoxes } from './DashboardPage.styled';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getClients,
  getContacts,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { useEffect, useState } from 'react';
import {
  retrieveClients,
  retrieveContacts,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { Box } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
  IClientStatisticBody,
  IReceiveContactBody,
  ITool,
} from 'types/api.type';
import {
  CLIENTS_TABLE,
  SERVICES_TABLE,
  TEAM_MEMBERS_TABLE,
} from 'constants/tablesHead';

interface IMenuOptions {
  onClickAdd: () => void;
  onClickViewAll: () => void;
  title: 'Team Members' | 'Clients' | 'Services';
  data: ITool[] | IClientStatisticBody[] | IReceiveContactBody[] | null;
  isToShow: boolean;
}

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tools = useAppSelector(getTools);
  const clients = useAppSelector(getClients);
  const teamMembers = useAppSelector(getContacts);
  const orgId = useAppSelector(getMainOrganizationID);

  const [selectedElement, setSelectedElement] = useState<
    'Team Members' | 'Clients' | 'Services' | null
  >(null);

  const connectedTools = tools.filter(
    tool =>
      (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
      (tool.clients_count !== null && tool.clients_count !== 0)
  );

  const reformatedClients = Array.isArray(clients)
    ? clients.map(client => {
        return {
          name: client.name,
          dev_count: client.devices_count,
          admin_count: client.admins_count,
          alert_count: client.alerts_count,
          tool_count: client.tools_count,
        };
      })
    : [];

  const reformatedContacts = Array.isArray(teamMembers)
    ? teamMembers.map(teamMember => {
        return {
          first_name: teamMember.first_name,
          last_name: teamMember.last_name,
          phone: teamMember.phone,
          email: teamMember.email,
          role: teamMember.role?.name,
        };
      })
    : [];

  const formattedServices = Array.isArray(connectedTools)
    ? connectedTools.map(connectedTool => {
        const connectionStatus = 'Connected';
        return {
          service_name: connectedTool.name,
          client_count: connectedTool.clients_count,
          devices: connectedTool.devices_count,
          status: connectedTool.status?.name,
          is_connected: connectionStatus,
        };
      })
    : [];

  const options: IMenuOptions[] = [
    {
      onClickAdd: () => handlePageRedirection(ROUTES.ADD_CLIENT),
      onClickViewAll: () => handlePageRedirection(ROUTES.CLIENTS),
      title: 'Clients',
      data: clients,
      isToShow: Array.isArray(clients) && clients.length > 0 ? true : false,
    },
    {
      onClickAdd: () => handlePageRedirection(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER),
      onClickViewAll: () => handlePageRedirection(ROUTES.TEAM_MEMBERS),
      title: 'Team Members',
      data: teamMembers,
      isToShow:
        Array.isArray(teamMembers) && teamMembers.length > 1 ? true : false,
    },
    {
      onClickAdd: () => handlePageRedirection(ROUTES.ADD_SERVICE),
      onClickViewAll: () => handlePageRedirection(ROUTES.SERVICES),
      title: 'Services',
      data: connectedTools,
      isToShow:
        Array.isArray(connectedTools) && connectedTools.length > 0
          ? true
          : false,
    },
  ];

  useEffect(() => {
    dispatch(retrieveClients(null));
    dispatch(retrieveTools(null));
    dispatch(retrieveContacts(orgId));
  }, [dispatch, orgId]);

  const handlePageRedirection = (route: any) => {
    navigate(route);
  };

  const handleAddClient = (option: 'Team Members' | 'Clients' | 'Services') => {
    switch (option) {
      case 'Team Members':
        navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
        break;
      case 'Clients':
        navigate(ROUTES.ADD_CLIENT);
        break;
      case 'Services':
        navigate(ROUTES.ADD_SERVICE);
        break;
      default:
        return;
    }
  };
  const handleDeleteClient = (
    option: 'Team Members' | 'Clients' | 'Services'
  ) => {
    switch (option) {
      case 'Team Members':
        navigate(ROUTES.DELETE_TEAM_MEMBER);
        break;
      case 'Clients':
        navigate(ROUTES.DELETE_CLIENT);
        break;
      case 'Services':
        navigate(ROUTES.DELETE_INTEGRATION);
        break;
      default:
        return;
    }
  };

  return (
    <WrapperGeneral>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="25px"
        width="100%"
      >
        <Search />
        <IconButton>
          <NotificationsNoneOutlinedIcon fontSize="medium" />
        </IconButton>
        <IconButton>
          <AccountCircleOutlinedIcon fontSize="medium" />
        </IconButton>
      </Box>
      <MenuInfoBoxes>
        {options
          .filter(option => option.isToShow === true)
          .map((option, index) => (
            <span
              role="button"
              key={index}
              onClick={() => setSelectedElement(option.title)}
              tabIndex={0}
            >
              <GeneralStatistics
                onClickAdd={option.onClickAdd}
                onClickViewAll={option.onClickViewAll}
                width="25vw"
                //height="30vh"
                title={option.title}
                data={option.data}
                isactive={selectedElement === option.title ? 'true' : 'false'}
              />
            </span>
          ))}
      </MenuInfoBoxes>
      {selectedElement === 'Clients' && (
        <TableBasis
          tableData={reformatedClients}
          columns={CLIENTS_TABLE}
          title={selectedElement}
          icon="edit"
          action={
            <SelectActions
              name={selectedElement}
              onAdd={() => handleAddClient(selectedElement)}
              onDelete={() => handleDeleteClient(selectedElement)}
            />
          }
        />
      )}

      {selectedElement === 'Team Members' && (
        <TableBasis
          tableData={reformatedContacts}
          columns={TEAM_MEMBERS_TABLE}
          title={selectedElement}
          icon="edit"
          action={
            <SelectActions
              name={selectedElement}
              onAdd={() => handleAddClient(selectedElement)}
              onDelete={() => handleDeleteClient(selectedElement)}
            />
          }
        />
      )}

      {selectedElement === 'Services' && (
        <TableBasis
          tableData={formattedServices}
          columns={SERVICES_TABLE}
          title={selectedElement}
          icon="edit"
          action={
            <SelectActions
              name={selectedElement}
              onAdd={() => handleAddClient(selectedElement)}
              onDelete={() => handleDeleteClient(selectedElement)}
            />
          }
        />
      )}
    </WrapperGeneral>
  );
};

export default DashboardPage;
