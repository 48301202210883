import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperITDR = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  paddingTop: '30px',
  gap: '50px',
}));

export const IncidentsCountersWrapper = styled(Box)<BoxProps>(() => ({
  width: '80vw',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const TitleActive = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.redMain,
  fontFamily: INTER,
  fontSize: '26px',
  fontWeight: 500,
  lineHeight: '34px',
  letterSpacing: '-0.358px',
}));
