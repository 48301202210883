import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { SUPER_ADMIN } from 'constants/organizationsType';
import { Link } from 'react-router-dom';
import { IAdminType } from 'types';
import { ISTOK_WEB, INTER } from 'constants/fonts';

interface IStyledTabsProps extends TabsProps, IAdminType {}

export const StyledBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  '& .MuiTabs-indicator': {
    height: '0px',
  },
}));

export const CompanyName = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '100%',
  letterSpacing: '-0.537px',
}));

export const StyledTabs = styled(Tabs)<IStyledTabsProps>(({ type }) => ({
  paddingTop: '30px',
  '& .MuiTabs-flexContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '15px',
    background: 'inherit',
  },
  '& .MuiTab-root': {
    gap: '7px',
    justifyContent: 'start',
    '& .MuiTab-iconWrapper': {
      margin: 0,
    },
  },
}));

export const StyledLink = styled(Link)(({ theme, type }) => ({
  '&.MuiButtonBase-root': {
    paddingLeft: '16px',
    width: '100%',
    height: '40px',
    minHeight: '40px',
    borderRadius: '6px',
    textTransform: 'none',
    transition: '0.15s linear',
    color:
      type === SUPER_ADMIN
        ? theme.palette.silver.contrastText
        : theme.palette.secondary.main,
    fontFamily: type === SUPER_ADMIN ? ISTOK_WEB : INTER,
    lineHeight: type === SUPER_ADMIN ? '24px' : '20px',
    letterSpacing: '-0.352px',
  },
  '&.MuiButtonBase-root.MuiTab-root': {
    fontSize: '16px',
    fontWeight: 400,
    color:
      type === SUPER_ADMIN
        ? theme.palette.silver.contrastText
        : theme.palette.secondary.main,
    fontFamily: type === SUPER_ADMIN ? ISTOK_WEB : INTER,
    lineHeight: type === SUPER_ADMIN ? '24px' : '20px',
    letterSpacing: '-0.352px',
    '&.Mui-selected': {
      fontWeight: type === SUPER_ADMIN ? 700 : 400,
      background:
        type === SUPER_ADMIN ? 'transparent' : theme.palette.primary.main,
      color:
        type === SUPER_ADMIN
          ? theme.palette.primary.main
          : theme.palette.common.white,
    },
    '&:hover': {
      fontWeight: type === SUPER_ADMIN ? 700 : 400,
      background:
        type === SUPER_ADMIN ? 'transparent' : theme.palette.primary.main,
      color:
        type === SUPER_ADMIN
          ? theme.palette.primary.main
          : theme.palette.common.white,
    },
  },
}));
